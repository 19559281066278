<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card flat>
          <v-card-title>
            Reporte por Orden

            <vue-excel-xlsx
              class="ml-5"
              :data="itemsRSenior"
              :columns="columns"
              :filename="'Reporte por Orden'"
              :sheetname="'sheetname'"
              style="background: #c1c1c1; padding: 2px 10px; border-radius: 5px"
            >
              Descargar
            </vue-excel-xlsx>

            <v-autocomplete
              :items="itemsCampana"
              item-text="name"
              item-value="id"
              class="ml-5 mt-5"
              label="Seleccionar Campaña"
              v-model="id_campana"
              @change="loadRSenior()"
            >
            </v-autocomplete>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :sort-by="['junior']"
            :headers="headers"
            :items="itemsRSenior"
            :search="search"
          >
            <template slot="body.append">
              <tr class="pink--text">
                <th colspan="0" class="title">Totales</th>
                <th class="title">{{ sumField("cantidad_or") }}</th>
                <th class="title">{{ sumField("subtotal_or") }}</th>
              </tr>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon
                class="mr-2 mx-auto"
                color="error"
                @click="deleteItem(item.id_orden)"
              >
                mdi-delete
              </v-icon>
              <v-icon
                class="mr-2 mx-auto"
                color="success"
                v-if="item.status == 1"
                @click="lockitem(item.id_orden)"
              >
                mdi-lock-open
              </v-icon>
              <v-icon
                class="mr-2 mx-auto"
                v-if="item.status == 2"
                color="error"
                @click="unlockitem(item.id_orden)"
              >
                mdi-lock
              </v-icon>
            </template>

            <template v-slot:item.details="{ item }">
              <v-icon
                class="mr-2 mx-auto"
                @click="loadRSeniorSS(item.id_orden)"
              >
                mdi-eye
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogrs" width="70%">
      <v-card>
        <v-card-title>
          Orden Nro {{ orden_orden }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchSS"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="1">
              <v-text-field
                v-model="orden_pagina"
                type="number"
                label="Página"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="orden_codigo_producto"
                label="Código"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="orden_producto"
                label="Producto"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="orden_cantidad"
                type="number"
                label="Cantidad"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="orden_precio"
                type="number"
                label="Precio"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                color="primary"
                v-if="statusDataOrden == 1"
                class="mt-3"
                @click="AddProduct()"
                block
                >Agregar</v-btn
              >
              <v-btn
                color="primary"
                v-if="statusDataOrden == 2"
                class="mt-3"
                @click="UpdateProduct()"
                block
                >Actualizar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-data-table
          :headers="headersSS"
          :items="itemsRSeniorSS"
          :search="searchSS"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon class="mr-2 mx-auto" @click="editItemOrden(item.id)">
              mdi-pencil
            </v-icon>
            <v-icon
              class="mr-2 mx-auto"
              color="error"
              @click="deleteItemOrden(item.id)"
            >
              mdi-delete
            </v-icon>
          </template>

          <template slot="body.append">
            <tr class="pink--text">
              <th colspan="5" class="title">Totales</th>
              <th colspan="0" class="title">{{ sumFieldS("cantidad") }}</th>
              <th class="title">{{ sumFieldS("precio") }}</th>
              <th class="title">{{ sumFieldS("subtotal") }}</th>
            </tr>
          </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogrs = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "RSenior",
  data: () => ({
    search: "",
    searchS: "",
    searchSS: "",
    senior: "",
    itemsRSenior: [],
    itemsRSeniorS: [],
    itemsRSeniorSS: [],
    dialogprecio: false,
    precio_compra: "",
    id: "",
    name: "",
    dialogrs: "",
    description: "",
    status: true,
    statusData: 1,

    orden_id: "",
    orden_cliente: "",
    orden_catalogo: "",
    orden_orden: "",
    orden_codigo_producto: "",
    orden_pagina: "",
    orden_producto: "",
    orden_cantidad: "",
    orden_precio: "",
    statusDataOrden: 1,

    itemsCampana: [],
    id_campana: "",

    headers: [
      {
        text: "Orden",
        value: "id_orden",
      },
      { text: "Cantidad", value: "cantidad_or" },
      { text: "Sub Total", value: "subtotal_or" },
      { text: "Senior", value: "senior" },
      { text: "Junior", value: "junior" },
      { text: "Detalles", value: "details" },
      { text: "Acción", value: "actions" },
    ],

    columns: [
      {
        label: "Orden",
        field: "id_orden",
      },
      { label: "Cantidad", field: "cantidad_or" },
      { label: "Sub Total", field: "subtotal_or" },
      { label: "Senior", field: "senior" },
      { label: "Junior", field: "junior" },
    ],

    headersSS: [
      {
        text: "Junior",
        align: "start",
        value: "junior",
      },
      { text: "Catálogo", value: "catalogo" },
      { text: "Código", value: "codigo_producto" },
      { text: "Página", value: "pagina" },
      { text: "Producto", value: "producto" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Precio", value: "precio" },
      { text: "Sub Total", value: "subtotal" },
      { text: "Acción", value: "actions" },
    ],
  }),
  mounted() {
    this.loadCampanas();
    //this.loadRSenior();
    this.loadRSeniorS();
  },
  methods: {
    loadRSenior() {
      axios
        .get(
          process.env.VUE_APP_API_URL + "rep/orden/campana/" + this.id_campana
        )
        .then((result) => {
          this.itemsRSenior = result.data;
        });
    },
    loadCampanas() {
      axios.get(process.env.VUE_APP_API_URL + "campana_all").then((result) => {
        this.itemsCampana = result.data;
      });
    },
    loadRSeniorS() {
      axios
        .get(process.env.VUE_APP_API_URL + "rep/junior_with")
        .then((result) => {
          this.itemsRSeniorS = result.data;
        });
    },
    lockitem(id) {
      let data = {
        status: 2,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "productos/lock/orden/" + id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    unlockitem(id) {
      let data = {
        status: 1,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "productos/lock/orden/" + id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    loadRSeniorSS(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "productos/r/orden/" + id)
        .then((result) => {
          this.dialogrs = true;
          this.orden_cliente = result.data[0].id_cliente;
          this.orden_catalogo = result.data[0].id_catalogo;
          this.orden_orden = result.data[0].id_orden;
          this.senior = result.data[0].senior;
          this.itemsRSeniorSS = result.data;
        });
    },
    editItem(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "RSenior/" + id)
        .then((result) => {
          this.id = result.data[0].id;
          this.name = result.data[0].name;
          this.description = result.data[0].description;
          this.status = result.data[0].status;
          this.statusData = 2;
        });
    },
    editItemOrden(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "productos/" + id)
        .then((result) => {
          this.orden_id = result.data[0].id;
          this.orden_cliente = result.data[0].id_cliente;
          this.orden_catalogo = result.data[0].id_catalogo;
          this.orden_orden = result.data[0].id_orden;
          this.orden_codigo_producto = result.data[0].codigo_producto;
          this.orden_pagina = result.data[0].pagina;
          this.orden_producto = result.data[0].producto;
          this.orden_cantidad = result.data[0].cantidad;
          this.orden_precio = result.data[0].precio;
          this.statusDataOrden = 2;
        });
    },
    addData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "RSenior", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro registrado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },

    AddProduct() {
      let data = {
        cliente: this.orden_cliente,
        catalogo: this.orden_catalogo,
        orden: this.orden_orden,
        codigo_producto: this.orden_codigo_producto,
        pagina: this.orden_pagina,
        producto: this.orden_producto,
        cantidad: this.orden_cantidad,
        precio: this.orden_precio,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "productos", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            this.loadRSeniorSS(this.orden_orden);
            this.cleanDataPro();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },

    UpdateProduct() {
      let data = {
        cliente: this.orden_cliente,
        catalogo: this.orden_catalogo,
        orden: this.orden_orden,
        codigo_producto: this.orden_codigo_producto,
        pagina: this.orden_pagina,
        producto: this.orden_producto,
        cantidad: this.orden_cantidad,
        precio: this.orden_precio,
      };
      axios
        .put(
          process.env.VUE_APP_API_URL + "productos/pro/" + this.orden_id,
          data
        )
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            this.loadRSeniorSS(this.orden_orden);
            this.cleanDataPro();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },

    updateData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "RSenior/" + this.id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.cleanData();
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    sumField(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSenior.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldS(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSeniorSS.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    deleteItem(id) {
      Swal.fire({
        title: "¿Está seguro de Eliminar?",
        text: "Una vez eliminada, la orden no se podrá recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title: "¿Está completamente seguro de Eliminar?",
            text: "Una vez eliminada, la orden NO se podrá recuperar",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "No, cancelar",
          }).then((result) => {
            if (result.value) {
              axios
                .delete(process.env.VUE_APP_API_URL + "productos/orden/" + id)
                .then((result) => {
                  console.log(result.data);
                  if (result.data.boolean == true) {
                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: "Orden eliminada con éxito",
                      showConfirmButton: true,
                      timer: 5000,
                    });
                    this.loadRSenior();
                  } else {
                    Swal.fire({
                      position: "center",
                      icon: "error",
                      title: "Oops...",
                      text: result.data.error.sqlMessage,
                      showConfirmButton: true,
                      timer: 8000,
                    });
                  }
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Cancelado", "La orden no fue eliminado", "error");
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "La orden no fue eliminado", "error");
        }
      });
    },

    deleteItemOrden(id) {
      Swal.fire({
        title: "¿Está seguro de Eliminar?",
        text: "Una vez eliminado, el producto no se podrá recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title: "¿Está completamente seguro de Eliminar?",
            text: "Una vez eliminado, el producto NO se podrá recuperar",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "No, cancelar",
          }).then((result) => {
            if (result.value) {
              axios
                .delete(
                  process.env.VUE_APP_API_URL + "productos/orden/id/" + id
                )
                .then((result) => {
                  console.log(result.data);
                  if (result.data.boolean == true) {
                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: "Procuto eliminado con éxito",
                      showConfirmButton: true,
                      timer: 5000,
                    });
                    this.loadRSeniorSS(this.orden_orden);
                  } else {
                    Swal.fire({
                      position: "center",
                      icon: "error",
                      title: "Oops...",
                      text: result.data.error.sqlMessage,
                      showConfirmButton: true,
                      timer: 8000,
                    });
                  }
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Cancelado", "El producto no fue eliminado", "error");
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "El producto no fue eliminado", "error");
        }
      });
    },
    cleanData() {
      this.name = "";
      this.description = "";
      this.status = true;
      this.statusData = 1;
    },

    cleanDataPro() {
      this.orden_codigo_producto = "";
      this.orden_pagina = "";
      this.orden_producto = "";
      this.orden_precio = "";
      this.orden_cantidad = "";
      this.status = true;
      this.statusDataOrden = 1;
    },
  },
};
</script>
